import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import ConfigurarAxios from "./lib/Axios";
import { ToastContainer } from "react-toastify";
import Page404 from "./views/Page404";
import Page500 from "./views/Page500";
import { Loading } from "./lib";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import LoginPage from "./views/Login";

const DefaultLayout = React.lazy(() =>
  import("./containers/DefaultLayout/DefaultLayout")
);
//const Register = React.lazy(() => import('./views/Pages/Register'));
const App = () => {
  ConfigurarAxios();


  return (
    <HashRouter>
      <ToastContainer />
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <LoginPage {...props} />}
          />
          {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} /> */}
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export { App };
