const perguntasDescricao = {
  header: "Título",
  paragraph: "Parágrafo",
  text: "Texto",
  number: "Numérico",
  switch: "Conforme / Não Corforme",
  checkbox: "Caixa de seleção",
  date: "Data",
  "date-time": "Data Hora",
  file: "Foto",
  signature: "Assinatura",
  vinculo: "Vínculo",
  "radio-group": "Multipla Escolha",
  select: "Multipla Escolha Suspensa",
  muiltifile: "Multiplas Fotos",
};

export { perguntasDescricao };
