import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import FieldInformation from '../../common/FieldInformation';

const CheckboxInput = ({ field, onChange, onLabelChange }) => {

    if (!field.nao_conforme) field.nao_conforme = false;
    const { label, nao_conforme } = field;

    return (
        <React.Fragment>
            <FormGroup check className="mb-3">
                <Input
                    name={`nao_conforme${field.id}`}
                    id={`nao_conforme${field.id}`}
                    type="checkbox"
                    checked={nao_conforme}
                    onChange={t => {
                        onChange({ nao_conforme: t.target.checked })
                    }} />
                <Label for={`nao_conforme${field.id}`} check>Não é conforme caso marcado</Label>
            </FormGroup>

            <FieldInformation field={field} onChange={onChange} />
        </React.Fragment>

    )

}

export default CheckboxInput;