import { gql, useQuery } from '@apollo/client';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { Card, Table, CardBody, Button, CardHeader, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, FormGroup, Label, ModalFooter, Dropdown } from 'reactstrap'
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import Axios from 'axios';
import { v4 } from 'uuid';
import { Loading } from '../../../lib';
const Query = gql`
query{
    pontosColetas(first: 1000, orderBy: [{column: ID, order : DESC}]){
        data{
            descricao,
            uuid
        },
        paginatorInfo{
            lastPage
        }
    }
}
`;
export const PontoColeta = props => {

    const { data, error, loading, startPolling, stopPolling, refetch } = useQuery(Query);
    const input = useRef();
    const [uuid, setUuid] = useState('');

    const cadastro = data ? data.pontosColetas.data.find(c => c.uuid === uuid) : null;

    const inicialValues = {
        descricao: cadastro ? cadastro.descricao : '',
        uuid: cadastro ? cadastro.uuid : v4()
    };

    useEffect(() => {

        startPolling();

        return () => stopPolling()
    })
    useEffect(() => {
        if (input && input.current) {
            input.current.focus();
        }
    }, input.current)
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    if (loading)
        return <Loading />

    if (error) {
        toast.error('Houve um erro ao buscar os dados');
        return <></>
    }

    return (
        <>
            <Card >
                <CardHeader>
                    <h2 className='d-inline font-weight-light'>Pontos Coleta</h2>
                    <div className="card-header-actions">
                        <Button className='btn btn-primary' onClick={toggle}>Novo</Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table responsive hover className="table-outline d-sm-table">
                        <thead className="thead-light">
                            <tr>
                                <th>Descrição</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.pontosColetas.data.map(c => (
                                <tr>
                                    <td>{c.descricao}</td>
                                    <td>
                                        <Acoes onEdit={() => {
                                            setUuid(c.uuid);
                                            toggle();
                                        }} />
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            {modal ?
                <Formik
                    enableReinitialize
                    initialValues={
                        inicialValues
                    }
                    onSubmit={async (values, actions) => {
                        try {
                            if (uuid) {
                                await Axios.patch('/pontos_coletas' + '/' + uuid, values);
                            } else {
                                await Axios.post('/pontos_coletas', values);
                            }
                            toast.success("Salvo com sucesso!");
                            toggle();
                            refetch();
                            setUuid('');
                        } catch (error) {
                            toast.error(error.message);
                        }
                        actions.setSubmitting(false);

                    }}>
                    {({ values, isSubmitting, errors, touched, submitForm }) => (
                        <Modal isOpen={modal} toggle={toggle}>

                            <Form>

                                <ModalHeader toggle={toggle}>
                                    {uuid ? 'Editar Ponto de Coleta' : 'Novo Ponto de Coleta'}
                                </ModalHeader>

                                <ModalBody>

                                    <FormGroup >
                                        <Label>Descrição</Label>
                                        <Field innerRef={input} autoFocus="autofocus" name={'descricao'} className="form-control" />
                                    </FormGroup>

                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={toggle}>
                                        Cancelar
                                    </Button>
                                    <Button color="primary" type="submit" disabled={isSubmitting} onClick={submitForm}>
                                        Salvar
                                    </Button>
                                </ModalFooter>
                            </Form>
                        </Modal>
                    )}
                </Formik>
                : null}
        </>

    )
};


function Acoes({ onEdit }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle color="link"><i className="icon-options-vertical icons font-2xl"></i></DropdownToggle>
            <DropdownMenu >
                <DropdownItem onClick={onEdit}>Editar</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}