import React from 'react';
import Form from '../../../components/Templates/Form';
import { v4 } from 'uuid';

export const FrmFuncionario = props => {

    const validate = (values) => {
        const errors = {};

        if (!values.nome) {
            errors.nome = 'É necessário informar o Nome';
        }
        if (!values.cpf) {
            errors.cpf = 'É necessário informar o CPF';
        }
        return errors;
    };

    return (
        <Form
            validate={validate}
            entidade='Colaborador'
            fields={[{ label: 'Nome', name: 'nome' }, { label: 'CPF', name: 'cpf' }]}
            inicialValues={{ nome: '', uuid: v4(), cpf: '' }}
            resource='funcionarios' />
    )
};