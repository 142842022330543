import React, { useEffect, useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import Api from '../../../../../lib/Api';
import FieldInformation from '../common/FieldInformation';
import FieldRequired from '../common/FieldRequired';
const VinculoInput = ({ field, onChange }) => {
    const [tabelasDinamicas, setTabelasDinamicas] = useState([]);
    useEffect(() => {
        (async _ => {
            const tabelasDinamicasData = await Api.fetchDinamicoTabelas();
            setTabelasDinamicas(tabelasDinamicasData)
        })();
    }, [])


    return (
        <><FormGroup>
            <label htmlFor="">Vínculo</label>
            <Input type='select' value={field.vinculo_tipo ?? ''} onChange={t => onChange({ ...field, vinculo_tipo: t.target.value })}>
                <option value="">Nenhum</option>
                <optgroup label='Cadastros'>
                    <option value="area">Área</option>
                    <option value="equipamento">Equipamento</option>
                    {/* <option value="produtor">Produtor</option>
                <option value="propriedade">Produtor</option> */}
                    <option value="funcionario">Colaborador</option>
                    <option value="ponto_coleta">Ponto de coleta</option>
                    <option value="setor">Setor</option>
                </optgroup>

                {tabelasDinamicas.length > 0 &&
                    <optgroup label='Dinâmicos'>
                        {tabelasDinamicas.map(t =>
                            <option value={t.uuid}>{t.descricao}</option>
                        )}
                    </optgroup>
                }

            </Input>
        </FormGroup>
            <FieldRequired field={field} onChange={onChange} />
            <FieldInformation field={field} onChange={onChange} />

        </>
    )
};
export default VinculoInput