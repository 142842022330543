import React, { useEffect, useState } from 'react';
import { Table, Thead } from '../../ui/Table/index';
import { Button, Input } from 'reactstrap';
const TableHeaders = ({ headers = [], onFiltroChange = () => { }, showFiltro = false, isLoading = false }) => {

    let inicialFiltros = {};

    headers.forEach(b => inicialFiltros[b.name] = '');

    const [filtros, setFiltros] = useState(inicialFiltros);

    function handleFiltroChange(e, h) {
        e.persist();
        setFiltros(s => ({ ...s, [h]: e.target.value }));
    }
    useEffect(() => {
        if (!isLoading) {
            const delayDebounceFn = setTimeout(() => {
                onFiltroChange(filtros);
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [filtros])
    return (
        <Thead >
            <tr>
                <th style={{ width: '2rem' }}>#</th>
                {headers.map(h => (<th>{h.label}</th>))}
            </tr>
            {showFiltro &&
                <tr  style={{borderBottom:"2px solid #c8ced3"}}>
                    <td>
                        <i className="fa fa-filter fa-lg"></i>
                    </td>
                    {headers.map(h => (
                        <td>
                            {!!h.filtravel && <InputFiltro
                                autoComplete="off"
                                type={h.type}
                                key={h.name}
                                name={h.name}
                                value={filtros[h.name]}
                                values={h.values}
                                onChange={e => handleFiltroChange(e, h.name)}
                                disabled={isLoading || h.disabled}
                            />}
                        </td>
                    ))}
                </tr>
            }
        </Thead>
    )
};

const InputFiltro = ({ type, value, name, onChange, values, disabled }) => {

    if (type == "select") {
        return <Input disabled={disabled} autoComplete="off" type='select' key={name} name={name} value={value} onChange={e => onChange(e)} >
            {values.map(v => (
                <option key={v.value} value={v.value}>{v.descricao}</option>
            ))}
        </Input>
    }
    return (
        <Input disabled={disabled} autoComplete="off" type='text' key={name} name={name} value={value} onChange={e => onChange(e)} />
    )
}

export default TableHeaders