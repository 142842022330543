import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { Alert, Button, FormGroup, Label, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { Loading } from '../../../lib';
export const AgendaEquipamentoForm = props => {

    const { uuid } = useParams();
    const [equipamentos, setEquipamentos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [foiGeradoAutomatico, setFoiGeradoAutomativo] = useState(false);
    const [agenda, setAgenda] = useState({
        uuid: v4(),
        equipamento_uuid: '',
        dia: moment().format('YYYY-MM-DD'),
        tipo: '',
        detalhamento: ''
    });

    useEffect(() => {
        (async _ => {

            try {
                setIsLoading(true);
                var req = await Axios.get('/equipamentos');
                setEquipamentos(req.data);

                if (uuid) {
                    const reqAgenda = await Axios.get('/equipamentos_agendas/' + uuid);
                    const dados = reqAgenda.data;
                    setAgenda(a => (
                        {
                            uuid: dados.uuid,
                            dia: dados.dia,
                            equipamento_uuid: dados.equipamento.uuid,
                            tipo: dados.tipo,
                            atividade: dados.atividade,
                            detalhamento: dados.detalhamento,
                        }));

                    setFoiGeradoAutomativo(Boolean(dados.programacao_id));
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }

        })();

    }, [])

    const history = useHistory();

    if (isLoading)
        return <Loading />


    return (
        <>
            <Card className="animated fadeIn">
                Agenda
                <CardBody>
                    <Formik initialValues={agenda} onSubmit={async (values, actions) => {
                        try {
                            if (uuid) {
                                await Axios.patch('/equipamentos_agendas/' + uuid, values);

                            } else {
                                await Axios.post('/equipamentos_agendas', values);
                            }
                            toast.success("Salvo com sucesso");

                            history.push('/equipamentos/agendas');

                        } catch (error) {
                            toast.error(error.message);
                        }
                        finally {
                            actions.setSubmitting(false);
                        }

                    }}>
                        {({ values }) => (

                            <Form>

                                <FormGroup>
                                    <Label>Equipamento</Label>
                                    <Field as="select" name="equipamento_uuid"
                                        disabled={foiGeradoAutomatico}
                                        required className="form-control">
                                        <option value=""></option>
                                        {equipamentos.map(eq => (
                                            <option value={eq.uuid}>{eq.descricao}</option>
                                        ))}
                                    </Field>


                                </FormGroup>

                                <FormGroup>
                                    <Label>Dia</Label>
                                    <Field type="date" name="dia" required className="form-control" />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Tipo</Label>
                                    <Field
                                        disabled={foiGeradoAutomatico}
                                        as="select" name="tipo" required className="form-control">
                                        <option value="M">Manutenção</option>
                                        <option value="H">Higienização</option>
                                        <option value="C">Calibração</option>
                                    </Field>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Atividade</Label>
                                    <Field name="atividade" className="form-control" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Detalhamento</Label>
                                    <Field name="detalhamento" className="form-control" as="textarea" rows={6} />
                                </FormGroup>

                                {foiGeradoAutomatico ?
                                    <Alert color="secondary" dismissible>
                                        <h6 className="alert-heading">Geração automática</h6>
                                        <p className='text-muted mb-0'>
                                            Obs: Alguns dados gerados automaticamentes são inalteráveis
                                        </p>
                                    </Alert>
                                    : null}

                                <FormGroup>
                                    <Button>
                                        Salvar
                                    </Button>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
};