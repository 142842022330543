import Axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableBody from '../../../components/Templates/Table/TableBody';
import TableFooter from '../../../components/Templates/Table/TableFooter';
import TableHeaders from '../../../components/Templates/Table/TableHeaders';
import { Card, CardActions, CardBody, CardHeader, CardTitle } from '../../../components/ui/Card';
import { Table } from '../../../components/ui/Table';
import { formatDate } from '../../../lib/DateUtil';

export const Programacoes = props => {

    const { equipamentoUuid } = useParams();
    const [higienizacoes, setHigienizacoes] = useState([]);
    const [equipamento, setEquipamento] = useState({});
    const [manutencoes, setManutencoes] = useState([]);
    const [calibraveis, setCalibraveis] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const tipos = ["M", "H","C"];

    const sets = {
        "H": data => setHigienizacoes(data),
        "M": data => setManutencoes(data),
        "C": data => setCalibraveis(data),
    }

    const descricao = {
        "H": "Higienização",
        "M": "Manutenção",
        "C": "Calibrável"
    }

    const gets = {
        "H": higienizacoes,
        "M": manutencoes,
        "C": calibraveis
    }

    useEffect(() => {

        (async _ => {
            setIsLoading(true);
            try {

                const requests = tipos.map(tipo => {
                    return (async _ => {
                        const req = await Axios.get(`/equipamentos/${equipamentoUuid}/programacoes?tipo=${tipo}`);
                        sets[tipo](req.data);
                    })();
                });

                requests.push(
                    (async _ => {
                        const req = await Axios.get(`/equipamentos/${equipamentoUuid}`);
                        setEquipamento(req.data);
                    })()
                )

                await Promise.all(requests)
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }

        })();

    }, [])

    const headers = [
        { label: 'Atividade', filtravel: true, name: 'atividade' },
        { label: 'Periodicidade', filtravel: true, name: 'periodicidade' },
        { label: 'Início', filtravel: true, name: 'inicio' },
        { label: 'Acões' },

    ]

    const body = [
        'atividade',
        'periodicidade',
        a => formatDate(a.inicio),
        (cadastro) => <Link to={`/equipamentos/${cadastro.equipamento.uuid}/programacoes/editar/${cadastro.uuid}`}>
            <i className={'cui-note font-2xl'}></i>
        </Link>,
    ]

    return (
        <>
            {!isLoading ? <h2 className='text-muted mb-4'>Equipamento - {equipamento.descricao}</h2> : null}
            {tipos.map(tipo => (
                <Card>
                    <CardHeader>
                        <CardTitle>
                            Programações - {descricao[tipo]}
                        </CardTitle>

                        <CardActions>
                            <Link className='btn btn-primary' to={`/equipamentos/${equipamentoUuid}/programacoes/novo/${tipo}`}>
                                Novo
                            </Link>
                        </CardActions>
                    </CardHeader>
                    <CardBody>
                        <Table>
                            <TableHeaders
                                headers={headers}
                                isLoading={isLoading} />
                            <TableBody
                                isLoading={isLoading}
                                cadastros={gets[tipo]}
                                body={body} />
                            <TableFooter cadastros={gets[tipo]} />
                        </Table>
                    </CardBody>
                </Card>
            ))}

        </>
    )

};