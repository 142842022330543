import React from "react";
import { AppSwitch } from "@coreui/react";
import { FormGroup, Label, Input, Col } from "reactstrap";

const FieldInformation = ({ onChange, field }) => {
  const { hasInformation, information } = field;

  const switchChangeHandler = (t) => {
    if (t.target.checked) {
      onChange({ hasInformation: t.target.checked, information: "" });
    } else {
      onChange({ hasInformation: false, information: "" });
    }
  };

  return (
    <React.Fragment>
      <FormGroup row>
        <Label xs={6} sm={2}>
          Tem instrução
        </Label>
        <Col xs={6} sm={10}>
          <AppSwitch
            checked={hasInformation}
            label
            onChange={(t) => switchChangeHandler(t)}
            style={{ color: "primary" }}
          />
        </Col>
      </FormGroup>
      {hasInformation && (
        <FormGroup>
          <Input
            type="textarea"
            rows={3}
            value={information}
            onChange={(t) => {
              onChange({ information: t.target.value });
            }}
          />
        </FormGroup>
      )}
    </React.Fragment>
  );
};

export default FieldInformation;
