import Axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';

import Loading from '../../lib/Loading';
const FormUI = ({ inicialValues = {}, resource = '', entidade = '', fields = [], validate = () => { } }) => {

    const [redirect, setRedirect] = useState(false);
    const [cadastro, setCadastro] = useState(inicialValues);
    const [isLoading, setIsLoading] = useState(true);
    const { uuid } = useParams();

    useEffect(() => {

        (async _ => {
            try {
                if (uuid) {
                    setIsLoading(true);
                    const req = await Axios.get('/' + resource + '/' + uuid);
                    setCadastro(req.data);
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }

        })();
    }, [])

    if (redirect) {
        return <Redirect to={`/${resource}`} />
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        <Card>
            <CardHeader >
                <h2>{`${entidade}`}</h2>
            </CardHeader>
            <CardBody>
                <Formik
                    validate={validate}
                    enableReinitialize
                    initialValues={cadastro}
                    onSubmit={async (values, actions) => {
                        try {
                            if (uuid) {
                                const req = await Axios.patch('/' + resource + '/' + uuid, values);
                                toast.success(req.data.mensagem);
                            } else {
                                const req = await Axios.post('/' + resource, values);
                                toast.success(req.data.mensagem);
                            }
                            setRedirect(true);
                        } catch (error) {
                            toast.error(error.message);
                        }

                        actions.setSubmitting(false);
                    }}>
                    {({ values, isSubmitting, errors, touched }) => (
                        <Form>
                            {fields.map(f => (
                                <FormGroup key={f.name}>
                                    <Label>{f.label}</Label>
                                    {f.type === 'select' ?
                                        <Field name={f.name} className="form-control" autoFocus={f.autoFocus} as={f.type} >
                                            <option value="">Selecionar</option>
                                            {
                                                f.values.map(v => (<option key={v.uuid} value={v.uuid}>{v.descricao}</option>))
                                            }
                                        </Field> :
                                        <Field name={f.name} className="form-control" autoFocus={f.autoFocus} type={f.type} value={f.format ? f.format(values[f.name]) : values[f.name]} />}

                                    {errors[f.name] && touched[f.name] ? <div className='mt-2 text-danger' color='danger'>{errors[f.name]}</div> : null}
                                </FormGroup>
                            ))}

                            <FormGroup>
                                <Button type="submit" disabled={isSubmitting}>Salvar</Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card >

    )
};
export default FormUI