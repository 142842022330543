import { LOGIN, LOGOUT, SET_JWT } from '../actions/auth';

const inicialState = {
    razao: "",
    jwt: null,
    avatar: "",
    isAdmin: false,
}

const loginReducer = (state = inicialState, action) => {

    if (action.type === LOGIN) {
        return { ...state, ...action.payload };
    }

    if (action.type === LOGOUT) {
        return inicialState;
    }

    if (action.type === SET_JWT) {
        return { ...state, jwt: action.jwt }
    }
    return state;

}
export function GetisAdmin(state) {
    return state.auth.isAdmin;
}

export function getEstaAutenticado(state) {
    return !!state.auth.jwt
}

export default loginReducer;