import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { Formik, Form, Field } from 'formik';
import { Card, CardBody, CardTitle, CardHeader, CardActions } from '../../../components/ui/Card';
import Api from '../../../lib/Api';
import { Button, FormGroup, Label } from 'reactstrap';
import Loading from '../../../lib/Loading';
import { Table } from '../../../components/ui/Table';
import TableBody from '../../../components/Templates/Table/TableBody';
import TableHeaders from '../../../components/Templates/Table/TableHeaders';
export const DinamicoTabelaFrm = props => {

    const { uuid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [registros, setRegistros] = useState([]);

    const history = useHistory();

    const [tabela, setTabela] = useState({
        uuid: v4(),
        descricao: '',
    })

    useEffect(() => {

        (async _ => {
            try {
                setIsLoading(true);
                if (uuid) {
                    const data = await Api.fetchDinamicoTabela(uuid);
                    setTabela({
                        uuid: data.uuid,
                        descricao: data.descricao
                    })

                    const regs = await Api.fetchDinamicoRegistros({
                        tabela_uuid: uuid
                    })

                    setRegistros(regs);
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [])


    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle>
                        Tabela Dinâmica
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <Formik initialValues={tabela} onSubmit={async (values, actions) => {

                        try {
                            if (uuid) {
                                await Api.atualizarTabelaDinamica(uuid, values);
                            } else {
                                await Api.adicionarTabelaDinamica(values);
                            }
                            history.push('/dinamico/tabelas');
                            toast.success("Salvo com sucesso!")
                        } catch (error) {
                            toast.error(error.message)
                        } finally {
                            actions.setSubmitting(false);
                        }
                    }}>
                        {({ isSubmitting }) => (
                            <Form>
                                <FormGroup>
                                    <Label>
                                        Descrição
                                    </Label>
                                    <Field name="descricao" className="form-control" />
                                </FormGroup>
                                <FormGroup>
                                    <Button disabled={isSubmitting} type="submit">Salvar</Button>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>

            {uuid ?

                <Card>
                    <CardHeader>
                        <CardTitle>
                            Registros
                        </CardTitle>
                        <CardActions>
                            <Link className='btn btn-primary' to={`/dinamico/tabelas/editar/${uuid}/registros/novo`} >Novo</Link>
                        </CardActions>
                    </CardHeader>
                    <CardBody>
                        <Table>
                            <TableHeaders headers={[{ label: 'descricao' }, { label: 'Ações' }]} />
                            <TableBody cadastros={registros} body={['descricao',
                                (cadastro) => <Link to={`/dinamico/tabelas/editar/${uuid}/registros/editar/${cadastro.uuid}`}>
                                    <i className="cui-note icons font-2xl "></i>
                                </Link>]} />
                        </Table>
                    </CardBody>
                </Card>
                : null}

        </>
    )
};