import React from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Card, CardBody, Button, CardHeader } from 'reactstrap';
import { useQuery, gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { Loading } from '../../../lib';
import { vinculos } from '../../../utils';
const GET_FORMS = gql`
    query Get($first: Int!, $page: Int!) {
    grupos(first:$first,page:$page){
    data{
      id,   
    descricao,
      uuid,
      esqueletos(orderBy:[{column:CODIGO,order:ASC},{column:NOME,order:ASC},{column:SUBTITULO,order:ASC}]){
        nome,
        uuid,
        codigo,
        vinculo,
        finalizado
      }
    }
    paginatorInfo {
        count,
        currentPage,
        lastPage
      }
  }
}
`;

export const FormularioLista = props => {
    const { loading, error, data } = useQuery(GET_FORMS, {
        variables: {
            first: 1000,
            page: 1
        },
        fetchPolicy: "no-cache"
    });

    const history = useHistory();
    const clickHandler = uuid => {
        history.push(`/formularios/editar/${uuid}`);
    }

    const navigateToNewHandler = () => {
        history.push(`/formularios/novo`);
    }

    if (loading) return <Loading />
    if (error) {
        toast.error(error.message);
        return null;
    }


    return (
        <Card className="animated fadeIn">
            <CardHeader className="d-flex justify-content-between align-items-center">
                <h2 className="font-weight-light">
                    Planilhas de registro
                </h2>
                <Button onClick={navigateToNewHandler}>Novo</Button>
            </CardHeader>
            <CardBody>
                <Table hover responsive className="table-outline d-sm-table">

                    <thead className='bg-gray-100'>
                        <tr>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Vínculo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.grupos.data.map(grupo =>
                            (
                                grupo.esqueletos.length > 0 ?
                                    <>
                                        <tr className='bg-gray-200 font-lg'>
                                            <td colSpan={'100%'}>{grupo.descricao}</td>
                                        </tr>
                                        {grupo.esqueletos.map(esq => (
                                            <tr key={esq.uuid} style={{ cursor: 'pointer' }} onClick={clickHandler.bind(this, esq.uuid)}>
                                                <td>{esq.codigo}</td>
                                                <td>{esq.nome}</td>
                                                <td>{vinculos[esq.vinculo]}</td>
                                                <td>
                                                    {esq.finalizado ?
                                                        <i className="icon-lock icons font-weight-bold font-2xl d-block"></i>
                                                        :
                                                        <i className="icon-lock-open  icons font-2xl d-block"></i>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                    : null
                            )
                            )
                        }
                    </tbody>
                </Table>
            </CardBody>
        </Card>

    )

};

