
const inicialState = {
    shouldPool: true,
    pooling: 10000
}


const parametrosReducer = (state = inicialState, action) => {
    return state;
}


export function GetPooling(state) {
    return state.parametros.pooling;
}


export function GetShouldPool(state) {
    return state.parametros.shouldPool;
}

export default parametrosReducer;