import { gql, useLazyQuery } from '@apollo/client';
import Axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Pagination, PaginationItem, PaginationLink, CardTitle, Col, Modal, ModalBody, ModalHeader, Row, Table, ModalFooter } from 'reactstrap';
import { formatDate, formatToBrasilTimeZone } from '../lib/DateUtil';
import Loading from '../lib/Loading';
import { toast } from 'react-toastify';
import { useRef } from 'react';

const LogModal = ({ registroId, toggle, isOpen, tabela }) => {
    const query = gql`
    query GetLogs($registroId:ID!, $page: Int,$tabela: String!){
    logs(registroId: $registroId,  first : 15, page:$page, tabela:$tabela){
      data{
        uuid,
        tabela,
        antes,
        tipo,
        colunas,
        depois,
        created_at
        usuario{
          nome
        }
      },
      paginatorInfo{
        lastPage
      }
    }
 }`

    const [getLogs, { loading, data: dados, error }] = useLazyQuery(query, {
        variables: {
            page: 1,
            first: 15,
            tabela: tabela
        },
        fetchPolicy: "no-cache"
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedId, setSelectedId] = useState();
    const selectedLog = useMemo(() => {
        return dados && selectedId ? dados.logs.data.find(d => d.uuid === selectedId) : null
    }, [dados, selectedId]);

    useEffect(() => {
        (async _ => {
            if (isOpen) {
                await getLogs({ variables: { page: currentPage, registroId: registroId } })
            }
        })();
    }, [isOpen, currentPage])

    useEffect(() => {

        if (dados && dados.logs.data.length >= 1) {
            setSelectedId(dados.logs.data[0].uuid);
        }
    }, [dados])
    const tipos = {
        INC: 'Inclusão',
        ALT: 'Alteração',
        EXC: 'Exclusão'
    }


    let selectedTableLog = null;

    if (selectedLog) {

        const colunas = selectedLog.colunas ? selectedLog.colunas.split("|") : [];
        const antes = selectedLog.antes ? selectedLog.antes.split("|") : [];
        const depois = selectedLog.depois ? selectedLog.depois.split("|") : [];

        selectedTableLog = colunas.map((c, i) => (
            <tr key={c}>
                <td>{c}</td>
                <td>{antes[i]}</td>
                <td>{depois[i]}</td>
            </tr>
        ));
    }

    const ref = useRef();

    if (ref.current) {
        ref.current.focus();
    }

    const onKeyDown = (e) => {
        if (loading) return;
        const key = e.key;
        const index = dados.logs.data.findIndex(d => d.uuid === selectedId);

        if (key == 'ArrowDown' && index < dados.logs.data.length - 1) {
            setSelectedId(dados.logs.data[index + 1].uuid);
        }
        else if (key == 'ArrowUp' && index > 0) {
            setSelectedId(dados.logs.data[index - 1].uuid);
        }
        else if (key == 'ArrowLeft' && currentPage > 1) {
            setCurrentPage(p => p - 1);
        }
        else if (key == 'ArrowRight' && currentPage < dados.logs.paginatorInfo.lastPage) {
            setCurrentPage(p => p + 1);
        }
        else if (key == "Escape") {
            toggle();
        }
    }

    if (error) {
        toast.error(error.message);
        return null;
    }

    if (!isOpen) return null;
    return (
        <div ref={ref}
            tabIndex="0"
            onKeyDown={onKeyDown}>
            <Modal
                className='text-nowrap font-xs'
                unmountOnClose style={{ maxWidth: '80%', width: '100%' }} centered scrollable isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Log</ModalHeader>
                <ModalBody>
                    {(dados == null || loading) ?
                        <Loading /> :
                        <Row >
                            <Col xs={12} lg={4}>
                                <Card body>
                                    <CardTitle tag="h5">Histórico</CardTitle>
                                    {loading ?
                                        <Loading /> :
                                        <>
                                            <Table

                                                striped size={"sm"} responsive hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Data</th>
                                                        <th>Tipo</th>
                                                        <th>Usuario</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dados.logs.data.map(log => (
                                                        <tr key={log.uuid} style={{ cursor: 'pointer' }} onClick={() => setSelectedId(log.uuid)}>
                                                            <td>
                                                                <input type="radio" name="" id="" checked={log.uuid === selectedId} />
                                                            </td>
                                                            <td>{formatToBrasilTimeZone(log.created_at)}</td>
                                                            <td>{tipos[log.tipo]}</td>
                                                            <td>{log.usuario ? log.usuario.nome : ''}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    }
                                </Card>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Card body>
                                    <CardTitle tag="h5">
                                        Detalhes
                                    </CardTitle>
                                    {loading ?
                                        <Loading /> :
                                        <Table striped size={"sm"} responsive hover>
                                            <thead>
                                                <tr >
                                                    <th>Campo</th>
                                                    <th>De</th>
                                                    <th>Para</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedTableLog}
                                            </tbody>
                                        </Table>
                                    }
                                </Card>
                            </Col>
                            <Col>
                                <Pagination className='d-flex justify-content-center'>
                                    <PaginationItem disabled={currentPage == 1}>
                                        <PaginationLink previous tag="button" onClick={() => setCurrentPage(c => c - 1)} />
                                    </PaginationItem >

                                    <PaginationItem active>
                                        <PaginationLink tag="button">
                                            {currentPage}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={currentPage === dados.logs.paginatorInfo.lastPage}>
                                        <PaginationLink next tag="button" onClick={() => setCurrentPage(c => c + 1)} />
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                        </Row>

                    }
                </ModalBody>
            </Modal>
        </div>
    )
};
export default LogModal