import Axios from 'axios';
import React from 'react';

export const ImageWithAuth = ({ url, style = {} }) => {

    // Components refs

    const img = React.useRef();

    // Load data
    React.useEffect(() => {

        Axios.get(url, { responseType: "blob" })
            .then(resp => {

                const url = URL.createObjectURL(resp.data);

                img.current.src = url;
            })
            .catch((e) => {

                img.current.alt = "Houve um erro ao baixar a imagem";

                console.log(e);
            });

    }, [url, img]);
    //
    return (
        <img src={""} alt={"Carregando..."} ref={img} style={style} />
    );

};

export default ImageWithAuth;