import React,{ useState }  from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  Label,
  FormGroup,
  Progress,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../../../store/actions/auth";

const RecuperarSenhaModal = (props) => {
  const dispatch = useDispatch();
  const totalSteps = 3;
  const [values, setValues] = useState({
    step: 0,
    email: props.email ? props.email : "",
    token: "",
    senha: "",
    success: false,
  });

  const nextHandler = () => {
    onChange("step", values.step + 1);
  };

  const backHandler = () => {
    onChange("step", values.step - 1);
  };
  const onChange = (name, value) => {
    setValues((v) => ({ ...v, [name]: value }));
  };

  const Steps = {
    0: (
      <Step1
        back={props.toggle}
        next={nextHandler}
        onChange={onChange}
        toggle={props.toggle}
        values={values}
      />
    ),
    1: (
      <Step2
        back={backHandler}
        next={nextHandler}
        onChange={onChange}
        values={values}
      />
    ),
    2: (
      <Step3
        back={backHandler}
        next={() => {
          nextHandler();
          onChange("success", true);
          setTimeout(async () => {
            try {
              await dispatch(login(values.email, values.senha));
            } catch (e) {
              props.toggle();
            }
          }, 2000);
        }}
        onChange={onChange}
        values={values}
      />
    ),
  };

  return (
    <Modal
      {...props}
      onClosed={() =>
        setValues({
          step: 0,
          email: props.email ? props.email : "",
          token: "",
          senha: "",
          success: false,
        })
      }
    >
      <ModalBody>
        <h4 className="text-center">Recuperação de senha</h4>
        <Progress
          color={values.success ? "success" : "primary"}
          className="my-3"
          style={{
            height: "3px",
          }}
          value={(values.step / totalSteps) * 100}
        />
        {Steps[values.step]}
        {values.success && (
          <div className="text-center">
            {" "}
            <p className="text-lg ">Senha recuperada com sucesso!</p>
            <p>Você será redirenciado</p>
            <Spinner />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

const Step1 = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const confirmClickHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.post("/forgot-password", {
        email: props.values.email,
      });
      props.next();
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };
  return (
    <Form onSubmit={confirmClickHandler}>
      <FormGroup>
        <Label size="lg" for="email">
          Email:
        </Label>
        <Input
          value={props.values.email}
          onChange={(e) => props.onChange("email", e.target.value)}
          type="email"
          id="email"
          name="email"
        ></Input>
      </FormGroup>
      <div className="text-right">
        <Button color="secondary" onClick={props.back}>
          Voltar
        </Button>
        <Button
          disabled={isLoading}
          type="submit"
          color="primary"
          className="ml-2"
        >
          {isLoading ? <Spinner size={"sm"} /> : "Próximo"}
        </Button>
      </div>
    </Form>
  );
};

const Step2 = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const confirmClickHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.post("/forgot-password/" + props.values.token);
      props.next();
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };
  return (
    <Form onSubmit={confirmClickHandler}>
      <FormGroup>
        <Label size="lg" for="codigo">
          Informe o código enviado para o seu email:
        </Label>
        <Input
          required
          type="text"
          value={props.values.token}
          onChange={(e) => props.onChange("token", e.target.value)}
        />
      </FormGroup>
      <div className="text-right">
        <Button color="secondary" onClick={props.back}>
          Voltar
        </Button>
        <Button
          disabled={isLoading}
          type="submit"
          color="primary"
          className="ml-2"
        >
          {isLoading ? <Spinner size={"sm"} /> : "Próximo"}
        </Button>
      </div>
    </Form>
  );
};

const Step3 = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const confirmClickHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.post("/reset-password/" + props.values.token, {
        senha: props.values.senha,
      });
      props.next();
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };
  return (
    <Form onSubmit={confirmClickHandler}>
      <FormGroup>
        <Label size="lg">Nova senha:</Label>
        <Input
          type="password"
          value={props.values.senha}
          onChange={(e) => props.onChange("senha", e.target.value)}
        />
      </FormGroup>
      <div className="text-right">
        <Button color="secondary" onClick={props.back}>
          Voltar
        </Button>
        <Button
          disabled={isLoading}
          type="submit"
          color="primary"
          className="ml-2"
        >
          {isLoading ? <Spinner size={"sm"} /> : "Finalizar"}
        </Button>
      </div>
    </Form>
  );
};
export default RecuperarSenhaModal;
