import React from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Card, CardBody, Button, Alert, CardHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import Loading from '../../../lib/Loading';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

export const GET_GRUPOS_QUERY = gql`
    query GetGrupos{
        grupos(first:1000,page:1){
            data{
                id
                uuid,
                descricao,
                esqueletosCount
            }
        }
    }
`
export const GruposLista = () => {

    const { loading, data, error, startPolling, stopPolling } = useQuery(GET_GRUPOS_QUERY, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        startPolling();
        return () => stopPolling();
    }, [])

    const history = useHistory();

    const clickHandler = uuid => {
        history.push(`/grupos/editar/${uuid}`);
    }

    const navigateToNewHandler = () => {
        history.push(`/grupos/novo`);
    }

    if (error) {
        toast.error(error.message);
        return null;
    }

    if (loading) return <Loading />

    return (
        <Card className="animated fadeIn">
            <CardHeader className="d-flex justify-content-between align-items-center">
                <h2 className="font-weight-light">
                    Grupos
                </h2>
                <Button onClick={navigateToNewHandler}>Novo</Button>
            </CardHeader>
            <CardBody>
                <Table hover responsive className="table-outline d-sm-table">

                    <thead className='bg-gray-100'>
                        <tr>
                            <th>Nome</th>
                            <th>Formulários Vinculados</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.grupos.data.map(grupo =>
                                <tr key={grupo.uuid} style={{ cursor: 'pointer' }} onClick={clickHandler.bind(this, grupo.uuid)}>
                                    <td>{grupo.descricao}</td>
                                    <td>{grupo.esqueletosCount}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

            </CardBody>

        </Card>

    )

};

//export default GruposLista;
