import { LOAD_NAVIGATION } from "../actions/navigation";

const inicialState = {
    items: [
        {
            name: 'Loading...  🕓',
            url: '/'
        }
    ]
};

const navigationReducer = (state = inicialState, action) => {

    if (action.type === LOAD_NAVIGATION) {
        return action.navigation;
    }
    return state;
}

export default navigationReducer;