import Axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import TableBody from '../../../components/Templates/Table/TableBody';
import TableFooter from '../../../components/Templates/Table/TableFooter';
import TableHeaders from '../../../components/Templates/Table/TableHeaders';
import { Card, CardActions, CardBody, CardHeader, CardTitle } from '../../../components/ui/Card';
import { Table, } from '../../../components/ui/Table';
import { formatDate } from '../../../lib/DateUtil';
import { buildUrlParams } from '../../../lib/Utils';
import { Calendar, dateFnsLocalizer, momentLocalizer } from 'react-big-calendar'
import moment from "moment";

require('moment/locale/pt-br')
const localizer = momentLocalizer(moment);
const currDate = new Date();

export const AgendaEquipamentos = props => {

    const [agendas, setAgendas] = useState([]);
    const [filtros, setFiltros] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {

        (async _ => {
            setIsLoading(true);
            let url = '/equipamentos_agendas?';
            if (filtros) {
                url += buildUrlParams(filtros);
            }
            const req = await Axios.get(url);
            setAgendas(req.data);
            setIsLoading(false);
        })();
    }, [filtros])

    const headers = [
        { label: 'Equipamento', filtravel: true, name: 'equipamento_nome' },
        { label: 'Tipo', filtravel: true, name: 'tipo', type: 'select', values: [{ descricao: 'Todos', value: '' }, { descricao: 'Manutenção', value: 'M' }, { descricao: 'Higienização', value: 'H' }, { descricao: 'Calibração', value: 'C' }] },
        { label: 'Atividade', filtravel: true, name: 'atividade' },
        { label: 'Dia' },
        { label: 'Status' },
        { label: 'Ações' },
    ];

    const hexColors = {
        H: '4dbd74',
        M: '20a8d8',
        C: 'e67e22'
    }

    const body = [
        a => a.equipamento.descricao,
        a => <Badge style={{ backgroundColor: '#' + hexColors[a.tipo] }}>{a.tipo_descricao}</Badge>,
        a => <div style={{ textOverflow: 'ellipsis', maxWidth: '200px', overflow: 'hidden', whiteSpace: 'nowrap' }} >{a.atividade}</div>,
        a => formatDate(a.dia),
        a => a.status_descricao,
        a => (<Link to={`/equipamentos/agendas/editar/${a.uuid}`}>
            <i className="cui-note icons font-2xl "></i>
        </Link>)
    ];

    const events = agendas.map(a => ({
        title: a.equipamento.descricao + ' : ' + a.atividade,
        allDay: false,
        start: moment(a.dia).toDate(),
        end: moment(a.dia).toDate(),
        uuid: a.uuid,
        hexColor: hexColors[a.tipo]
    }))

    const history = useHistory();

    const styleGetter = function (event, start, end, isSelected) {
        var backgroundColor = '#' + event.hexColor;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            color: 'white',
            border: '0px',
            display: 'block'
        };

        return {
            style: style
        };
    }

    return (
        <>
            <Card>
                <CardBody>
                    <div className="animated">
                        <Card>
                            <CardHeader>
                                <div>
                                    <i className="icon-calendar mr-1"></i>Calendário{' '}
                                </div>
                            </CardHeader>
                            <CardBody style={{ height: '40rem' }}>
                                <Calendar className="d-sm-down-none"
                                    eventPropGetter={styleGetter}
                                    onSelectEvent={(a) => {
                                        history.push(`/equipamentos/agendas/editar/${a.uuid}`)
                                    }}
                                    localizer={localizer}
                                    events={events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 700 }}
                                    messages={{
                                        showMore: qtd => "+ " + qtd + " atividades",
                                        noEventsInRange: 'Nenhum evento',
                                        date: 'Data',
                                        event: 'Evento',
                                        time: 'Tempo',
                                        next: "Próximo",
                                        previous: "Anterior",
                                        today: "Hoje",
                                        month: "Mês",
                                        week: "Semana",
                                        day: "Dia"
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </div>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle>Agendas</CardTitle>
                    <CardActions>
                        <Link to={'/equipamentos/agendas/novo'} className="btn btn-primary">Novo</Link>
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <Table>
                        <TableHeaders showFiltro headers={headers} onFiltroChange={setFiltros} isLoading={isLoading} />
                        <TableBody
                            isLoading={isLoading}
                            cadastros={agendas}
                            body={body}
                        />
                        <TableFooter cadastros={agendas} />
                    </Table>
                </CardBody>
            </Card>

        </>
    )
};





