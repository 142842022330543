import React, { useEffect } from 'react';
import { Alert } from 'reactstrap'

const FlashMessage = ({ duracao, onEnd, type, mensagem }) => {

    duracao = duracao ? duracao : 3000;

    useEffect(() => {
        setTimeout(() => { onEnd() }, duracao);
    }, [duracao, onEnd]);

    return (
        <Alert color={type}>
            {mensagem}
        </Alert>
    );
}

export default FlashMessage;