import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../lib/Loading';
const TableBody = ({
  isLoading,
  body,
  cadastros,
  onRowSelect = () => { },
  selectedIds = [],
  body_href_index,
  showCheckbox = true,
  rowStyle = {},
  ...props
}) => {
  return (
    <tbody {...props}>
      {isLoading ? <td colSpan={'100%'}> <Loading /> </td> :
        cadastros.map((cadastro, i) => (
          <tr key={i} style={typeof rowStyle === 'function' ? rowStyle(cadastro) : rowStyle}>
            <td>
              {showCheckbox ?
                <input type="checkbox" onChange={t => onRowSelect(cadastro.uuid)} checked={selectedIds.includes(cadastro.uuid)} />
                : null
              }
            </td>
            {body.map((b, i) =>
              <td key={i} onClick={() => i === body_href_index ? onRowSelect(cadastro.uuid) : {}}>
                <span style={{ cursor: `${i === body_href_index ? 'pointer' : ''}` }} >
                  {typeof b === 'function' ? b(cadastro) : cadastro[b]}
                </span>
              </td>
            )}
          </tr>
        ))
      }
    </tbody>
  )
};
export default TableBody
