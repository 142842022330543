import React, { useContext, useState } from "react";
import {
  Input,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Diario, Mensal, Semanal } from "./Frequencia";
import { AppSwitch } from "@coreui/react";
import { EsqueletoFormContext } from "./EsqueletoForm";

const EsqueletoHeaderData = () => {

    const {
    changeEsqueletoHandler: onChange,
    esqueleto,
    colaboradores,
    grupos,
  } = useContext(EsqueletoFormContext);

  const [activeTab, setActiveTab] = useState("1");

  const diario = (
    <Diario
      frequencia={esqueleto.frequencia}
      onChange={(e) =>
        onChange("frequencia", {
          ...esqueleto.frequencia,
          horario: e.target.value,
        })
      }
    />
  );

  const semanal = (
    <Semanal
      frequencia={esqueleto.frequencia}
      onChange={(dia, valor) =>
        onChange("frequencia", { ...esqueleto.frequencia, [dia]: valor })
      }
      onChangeHorario={(horario) =>
        onChange("frequencia", {
          ...esqueleto.frequencia,
          horario: horario.target.value,
        })
      }
    />
  );

  const mensal = (
    <Mensal
      frequencia={esqueleto.frequencia}
      onChangeHorario={(horario) =>
        onChange("frequencia", {
          ...esqueleto.frequencia,
          horario: horario.target.value,
        })
      }
      onChange={(dia, valor) =>
        onChange("frequencia", { ...esqueleto.frequencia, [dia]: valor })
      }
    />
  );

  const relacao = {
    diario: diario,
    mensal: mensal,
    semanal: semanal,
  };

  const onChangeColaborador = (uuid, checked) => {
    if (checked) {
      const colaborador = colaboradores.find((c) => c.uuid === uuid);
      onChange("colaboradores", [...esqueleto.colaboradores, colaborador]);
    } else {
      const copy = [...esqueleto.colaboradores];
      const result = copy.filter((c) => c.uuid !== uuid);
      onChange("colaboradores", result);
    }
  };

  return (
    <div className="mb-4">
      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === "1"}
            onClick={() => {
              setActiveTab("1");
            }}
          >
            Principal
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "2"}
            onClick={() => {
              setActiveTab("2");
            }}
          >
            Vínculo
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "3"}
            onClick={() => {
              setActiveTab("3");
            }}
          >
            Restrições{" "}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "4"}
            onClick={() => {
              setActiveTab("4");
            }}
          >
            Frequência{" "}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "5"}
            onClick={() => {
              setActiveTab("5");
            }}
          >
            Instruções{" "}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "6"}
            onClick={() => {
              setActiveTab("6");
            }}
          >
            Integrações{" "}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={"1"}>
          <FormGroup>
            <Label>Grupo:</Label>
            <Input
              type="select"
              required
              onChange={(t) => onChange("grupo_uuid", t.target.value)}
              value={esqueleto.grupo_uuid}
            >
              <option value="">Nenhum definido</option>
              {grupos.map((grupo) => {
                return (
                  <option key={grupo.uuid} value={grupo.uuid}>
                    {grupo.descricao}
                  </option>
                );
              })}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label>Código:</Label>
            <Input
              type="text"
              placeholder="Codigo"
              onChange={(t) => onChange("codigo", t.target.value)}
              value={esqueleto.codigo}
            />
          </FormGroup>

          <FormGroup>
            <Label>Título:</Label>
            <Input
              type="text"
              placeholder="Nome dado ao Formulário"
              onChange={(t) => onChange("nome", t.target.value)}
              value={esqueleto.nome}
            />
          </FormGroup>

          <FormGroup>
            <Label>Subtítulo:</Label>
            <Input
              type="text"
              placeholder="Subtítulo dado ao Formulário"
              onChange={(t) => onChange("subtitulo", t.target.value)}
              value={esqueleto.subtitulo}
            />
          </FormGroup>

          <FormGroup check inline>
            <Label check>
              <Input
                value={"M"}
                type="radio"
                onChange={(e) => onChange("tipo", "M")}
                checked={esqueleto.tipo === "M"}
              />
              Monitoramento
            </Label>
          </FormGroup>

          <FormGroup check inline>
            <Label check>
              <Input
                value={"V"}
                type="radio"
                onChange={(e) => onChange("tipo", "V")}
                checked={esqueleto.tipo === "V"}
              />
              Verificação
            </Label>
          </FormGroup>

          <FormGroup check inline>
            <Label check>
              <Input
                value={"E"}
                type="radio"
                onChange={(e) => onChange("tipo", "E")}
                checked={esqueleto.tipo === "E"}
              />
              Execução
            </Label>
          </FormGroup>
        </TabPane>
        <TabPane tabId={"2"}>
          <FormGroup>
            {" "}
            <Label htmlFor="vinculo">Vínculo</Label>
            <Input
              type="select"
              name="vinculo"
              value={esqueleto.vinculo}
              onChange={(t) => onChange("vinculo", t.target.value)}
            >
              <option value="">Nenhum</option>
              <option value="produtor">Produtor</option>
              <option value="setor">Setor</option>
              <option value="equipamento">Equipamento</option>
              <option value="funcionario">Funcionário</option>
              <option value="ponto_coleta">Ponto Coleta</option>
              <option value="reservatorio">Reservatório</option>
            </Input>
          </FormGroup>
        </TabPane>
        <TabPane tabId={"3"}>
          <FormGroup check inline>
            <Label check>Tem restrição de usuário</Label>
            <AppSwitch
              className={"mx-1"}
              variant={"pill"}
              color={"primary"}
              label
              onChange={(t) => {
                onChange("tem_restricao_colaborador", t.target.checked);
              }}
              checked={Boolean(esqueleto.tem_restricao_colaborador)}
            />
          </FormGroup>

          <FormGroup>
            {Boolean(esqueleto.tem_restricao_colaborador) &&
              colaboradores.map((s) => (
                <FormGroup check inline key={s.uuid}>
                  <Label check>
                    <Input
                      value={s.uuid}
                      onChange={(e) =>
                        onChangeColaborador(s.uuid, e.target.checked)
                      }
                      checked={esqueleto.colaboradores.some(
                        (c) => c.uuid === s.uuid
                      )}
                      type="checkbox"
                    />{" "}
                    {s.nome}
                  </Label>
                </FormGroup>
              ))}
          </FormGroup>
        </TabPane>
        <TabPane tabId={"4"}>
          <FormGroup>
            <Label>Frequência</Label>
            <Input
              type="select"
              name="frequencia"
              value={esqueleto.frequencia.tipo}
              onChange={(t) => onChange("frequencia", { tipo: t.target.value })}
            >
              <option value="">Nenhuma definida</option>
              <option value="diario">Diário</option>
              <option value="semanal">Semanal</option>
              <option value="mensal">Mensal</option>
            </Input>
          </FormGroup>

          {esqueleto.frequencia && relacao[esqueleto.frequencia.tipo]}
        </TabPane>
        <TabPane tabId={"5"}>
          <FormGroup>
            <Input
              type="textarea"
              rows={10}
              placeholder="Instruções"
              onChange={(t) => onChange("instrucao", t.target.value)}
              value={esqueleto.instrucao}
            />
          </FormGroup>
        </TabPane>
        <TabPane tabId={"6"}>
          <FormGroup className="">
            <Label>Url Notificação</Label>
            <Input
              type="text"
              placeholder="Url para integração"
              onChange={(t) => onChange("notificacao_url", t.target.value)}
              value={esqueleto.notificacao_url}
            />
            <FormGroup check>
              <Label check>
                <Input
                  value="0"
                  type="radio"
                  onChange={(t) => onChange("notificacao_tipo", 0)}
                  checked={esqueleto.notificacao_tipo === 0}
                />
                Notificar Não Conformidades
              </Label>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input
                  value="1"
                  type="radio"
                  onChange={(t) => onChange("notificacao_tipo", 1)}
                  checked={esqueleto.notificacao_tipo === 1}
                />
                Notificar Sempre
              </Label>
            </FormGroup>
          </FormGroup>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default EsqueletoHeaderData;
