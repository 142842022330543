import React, { useEffect } from 'react';
import { useState } from 'react';
const useTimer = ({ timer, onRefresh }) => {
    const [refreshTimer, setRefreshTimer] = useState(timer);

    useEffect(() => {
        let timout = setTimeout(() => setRefreshTimer(p => p - 1000), 1000);
        if (refreshTimer == 0) {
            onRefresh();
            setRefreshTimer(timer);
        }
        return () => clearTimeout(timout);
    }, [refreshTimer]);


    return { refreshTimer }

};
export default useTimer