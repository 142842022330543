import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import FieldRequired from '../../common/FieldRequired';

const Signature = ({ field, onChange }) => {


    return (
        <FormGroup>
            <FieldRequired field={field} onChange={onChange} />
        </FormGroup>
    )
}

export default Signature;