import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableBody from '../../../components/Templates/Table/TableBody';
import TableFooter from '../../../components/Templates/Table/TableFooter';
import TableHeaders from '../../../components/Templates/Table/TableHeaders';
import { Card, CardBody, CardTitle, CardHeader, CardActions } from '../../../components/ui/Card';
import { Table } from '../../../components/ui/Table';
import Api from '../../../lib/Api';
export const DinamicoTabela = props => {

    const [tabelas, setTabelas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {

        (async _ => {
            try {
                setIsLoading(true);
                const data = await Api.fetchDinamicoTabelas();
                setTabelas(data);
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [])

    const headers = [{ label: "Descrição" }, { label: "Ações" }];
    const body = [
        'descricao',
        (cadastro) => <Link to={`/dinamico/tabelas/editar/${cadastro.uuid}`}>
            <i className="cui-note icons font-2xl "></i>
        </Link>
    ]

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle>
                        Cadastros Dinâmicos
                    </CardTitle>
                    <CardActions>
                        <Link className='btn btn-primary' to={'/dinamico/tabelas/novo'}>Novo</Link>
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <Table>
                        <TableHeaders headers={headers} />
                        <TableBody
                            isLoading={isLoading}
                            body={body}
                            cadastros={tabelas} />
                        <TableFooter cadastros={tabelas} />
                    </Table>
                </CardBody>
            </Card>
        </>
    )
};