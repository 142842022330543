import React, { useState, createContext, useCallback } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { v4 as uuidv4, v4 } from "uuid";
import EsqueletoHeaderData from "./EsqueletoHeaderData";
import { toast } from "react-toastify";
import "./styles.css";
import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Loading from "../../../lib/Loading";
import Api from "../../../lib/Api";
import Axios from "axios";
import EsqueletoBodyData from "./EsqueletoBodyData";

export const EsqueletoFormContext = createContext();

export const EsqueletoForm = (props) => {
  const { esqueletoUuid } = useParams();
  const history = useHistory();

  const [esqueleto, setEsqueleto] = useState({
    codigo: "",
    uuid: uuidv4(),
    nome: "",
    subtitulo: "",
    perguntas: [],
    instrucao: "",
    frequencia: { tipo: "" },
    tem_restricao_colaborador: false,
    colaboradores: [],
    pergunta_pelo_produtor: false,
    grupo_uuid: "",
    vinculo: "",
    notificacao_url: "",
    notificacao_tipo: undefined,
    finalizado: false,
    tipo: "",
  });

  const [error, setError] = useState();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [grupos, setGrupos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const readOnly = Boolean(esqueleto.finalizado);
  const inicialToggleState = Boolean(!esqueletoUuid);

  useEffect(() => {
    setIsLoading(true);
    (async (_) => {
      try {
        if (esqueletoUuid) {
          const [esqueletoData, error1] = await Api.fetchEsqueleto(
            esqueletoUuid
          );
          if (!error1) {
            setEsqueleto((s) => {
              return {
                ...s,
                ...esqueletoData,
              };
            });
          }
        }
        const gruposData = await Api.fetchGrupos();
        const colaboradoresData = await Api.fetchColaboradores();
        setGrupos(gruposData);

        setColaboradores(colaboradoresData);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [esqueletoUuid]);

  const perguntaChangeHandler = useCallback((updatedField) => {
    setEsqueleto((old) => {
      const perguntasCopy = [...old.perguntas];
      const index = perguntasCopy.findIndex((i) => i.id === updatedField.id);
      perguntasCopy[index] = updatedField;
      return { ...old, perguntas: perguntasCopy };
    });
  }, []);

  const addNovoCampoHandler = () => {
    const novasPerguntas = [
      ...esqueleto.perguntas,
      {
        id: uuidv4(),
        name: "text_" + v4(),
        type: "text",
        required: false,
        label: "",
      },
    ];
    changeEsqueletoHandler("perguntas", novasPerguntas);
  };
  const deleteHandler = useCallback((fieldId) => {
    setEsqueleto((old) => {
      const perguntasCopy = [...old.perguntas.filter((p) => p.id !== fieldId)];
      return { ...old, perguntas: perguntasCopy };
    });
  }, []);

  const upClickHandler = useCallback((id) => {
    setEsqueleto((old) => {
      const perguntasCopy = [...old.perguntas];
      const index = perguntasCopy.findIndex((i) => i.id === id);

      if (index <= 0) {
        return old;
      }

      let temp = perguntasCopy[index];
      perguntasCopy[index] = perguntasCopy[index - 1];
      perguntasCopy[index - 1] = temp;
      return { ...old, perguntas: perguntasCopy };
    });
  }, []);

  const downClickHandler = useCallback((id) => {
    setEsqueleto((old) => {
      const perguntasCopy = [...old.perguntas];
      const index = perguntasCopy.findIndex((i) => i.id === id);

      if (perguntasCopy.length === index + 1) {
        return old;
      }

      let temp = perguntasCopy[index];
      perguntasCopy[index] = perguntasCopy[index + 1];
      perguntasCopy[index + 1] = temp;
      return { ...old, perguntas: perguntasCopy };
    });
  }, []);

  const changeEsqueletoHandler = useCallback((name, value) => {
    setEsqueleto((s) => {
      return { ...s, [name]: value };
    });
  }, []);

  const submitHandler = async () => {
    if (!esqueleto.nome) {
      setError("Está faltando preencher o nome");
      return;
    }

    if (!esqueleto.grupo_uuid) {
      setError("Está faltando preencher o Grupo");
      return;
    }

    if (esqueleto.perguntas.length === 0) {
      setError("Está faltando preencher as perguntas");
      return;
    }
    let error = null;

    setIsSubmiting(true);
    if (esqueletoUuid) {
      const [resp, errorReq] = await Api.atualizarEsqueleto(esqueleto);
      error = errorReq;
    } else {
      const [response, errorReq] = await Api.adicionarFormulario(esqueleto);
      error = errorReq;
    }

    if (!error) {
      toast.success("Salvo com sucesso!");
      history.push({
        pathname: "/formularios",
      });
    } else {
      toast.error("Erro ao salvar.");
    }
    setIsSubmiting(false);
  };

  const finalizarHandler = async () => {
    var x = window.confirm(
      "Depois de finalizado, não será possível realizar modificações.\nDeseja realmente Finalizar?"
    );
    if (x) {
      try {
        await Axios.post(`/esqueletos/${esqueletoUuid}/finalizar`);
        changeEsqueletoHandler("finalizado", true);
        toast.success("Finalizado com sucesso!");
      } catch (e) {
        toast.error("Houve um erro ao finalizar");
      }
    }
  };

  const typeChangeHandler = useCallback(
    (field, type) => {
      if (readOnly) return;

      const newField = {
        id: field.id,
        type: type,
        label: field.label,
        name: type + "_" + field.id,
      };

      perguntaChangeHandler(newField);
    },
    [readOnly, perguntaChangeHandler]
  );

  if (isLoading) return <Loading />;

  return (
    <EsqueletoFormContext.Provider
      value={{
        typeChangeHandler,
        perguntaChangeHandler,
        changeEsqueletoHandler,
        upClickHandler,
        downClickHandler,
        deleteHandler,
        inicialToggleState,
        grupos,
        readOnly,
        colaboradores,
        esqueleto,
      }}
    >
      <Card>
        <CardHeader className="h2 font-weight-light">
          <CardTitle>Construtor de Planilha</CardTitle>
        </CardHeader>

        <form
          className="animated fadeIn"
          aria-label="form"
          onSubmit={submitHandler}
        >
          <CardBody>
            <EsqueletoHeaderData />
            <EsqueletoBodyData />
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  disabled={Boolean(esqueleto.finalizado)}
                  onClick={addNovoCampoHandler}
                  color="primary"
                  className="btn-pill mr-2"
                >
                  Nova Pergunta
                </Button>
              </div>

              <div>
                {esqueletoUuid ? (
                  <Button
                    disabled={Boolean(esqueleto.finalizado)}
                    onClick={finalizarHandler}
                    color={esqueleto.finalizado ? "" : "danger"}
                    className="btn-pill mr-2"
                  >
                    {esqueleto.finalizado
                      ? "Planilha Oficializada"
                      : "Oficializar Planilha"}
                  </Button>
                ) : null}
                <Button
                  type="submit"
                  onClick={submitHandler}
                  className="btn btn-pill btn-success btn-ladda"
                  data-color="green"
                  disabled={isSubmiting}
                >
                  {isSubmiting ? <i className="fa fa-spinner"></i> : "Salvar"}
                </Button>
              </div>
            </div>
          </CardFooter>
          {error && (
            <Alert color="danger" className="mt-2">
              {error}{" "}
            </Alert>
          )}
        </form>
      </Card>
    </EsqueletoFormContext.Provider>
  );
};
