import removeAccents from 'remove-accents'

export const convertLabelToName = (label, type) => {
    const labelFixed = removeAccents(label);
    const name = type + '_' + labelFixed.split(' ').map(word => word).join('_').toLowerCase();
    return name;
}

export const buildUrlParams = params => { 
    
    let url = '';
    for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
            if (params[key])
                url += `${key}=${params[key]}&`
        }
    }
    return url;
}
