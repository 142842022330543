import React, { useContext } from "react";
import FormInput from "./formInput/Render";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { EsqueletoFormContext } from "./EsqueletoForm";

const EsqueletoBodyData = () => {
  const {
    esqueleto,
    readOnly,
    inicialToggleState,
    changeEsqueletoHandler,
    perguntaChangeHandler,
    deleteHandler,
    upClickHandler,
    downClickHandler,
    typeChangeHandler,
  } = useContext(EsqueletoFormContext);

  const { perguntas } = esqueleto;
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      perguntas,
      result.source.index,
      result.destination.index
    );

    changeEsqueletoHandler("perguntas", items);
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {perguntas.map((field, index) => (
              <Draggable key={field.id} draggableId={field.id} index={index}>
                {(providedB, snapshot) => (
                  <div
                    ref={providedB.innerRef}
                    {...providedB.draggableProps}
                    {...providedB.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      providedB.draggableProps.style
                    )}
                  >
                    <FormInput
                      index={index}
                      downClickHandler={downClickHandler}
                      inicialToggleState={inicialToggleState}
                      typeChangeHandler={typeChangeHandler}
                      upClickHandler={upClickHandler}
                      readOnly={readOnly}
                      deleteHandler={deleteHandler}
                      perguntaChangeHandler={perguntaChangeHandler}
                      key={field.id}
                      field={field}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default EsqueletoBodyData;
