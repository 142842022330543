import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import ModalPreenchidos from '../../../components/Templates/ModalPreenchidos';
import TableTeamplate from '../../../components/Templates/Table/TableTeamplate';
import Api from '../../../lib/Api';
export const Funcionarios = props => {

    const [modal, setModal] = useState();
    const [funcionarioId, setFuncionarioId] = useState();

    const modalToggle = (id) => {
        setFuncionarioId(id);
        setModal(p => !p);
    }

    return (<>
        <TableTeamplate
            end_point={'/funcionarios'}
            titulo='Colaboradores'
            entidade='funcionario'
            body={['nome',
                'cpf',
                (cadastro) =>
                    <div className='d-flex align-items-center'>
                        <Link to={`/funcionarios/editar/${cadastro.uuid}`}>
                            <i className="cui-note icons font-2xl "></i>
                        </Link>
                        <Button color="link" className="pl-2 py-0" onClick={() => modalToggle(cadastro.id)}>
                            <i className="cui-layers icons font-2xl"></i>
                        </Button>
                    </div>
            ]}
            headers={[
                { label: 'Nome', name: 'nome', filtravel: true },
                { label: 'CPF', name: 'cpf', filtravel: true }, { label: "Ações" }]}
            rota={'funcionarios'}
        />
        {funcionarioId ? <ModalPreenchidos
            filtros={{ funcionarioId: funcionarioId }}
            isOpen={modal}
            toggle={() => modalToggle()} /> : null}
    </>
    )
};
export default Funcionarios