import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const ParagraphInput = ({ label, onChange }) => {

    return (
        <React.Fragment>
        </React.Fragment>
    )

}

export default ParagraphInput;