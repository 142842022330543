import Axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { LOGOUT } from '../store/actions/auth';
import { API_URL } from "./Api";
const ConfigurarAxios = () => {

    const jwt = useSelector(state => state.auth.jwt);
    Axios.defaults.headers.common['jwt'] = jwt;

    const dispatch = useDispatch();

    Axios.defaults.baseURL = API_URL;

    Axios.defaults.headers.common['Content-Type'] = 'application/json';

    Axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response) {
            error.message = error.response.data.mensagem ? error.response.data.mensagem : error.message;
            if (error.response.status == 401) {
                dispatch({ type: LOGOUT })
            }
        } else if (error.request) {
            console.error(error);
            error.message = "Não houve resposta, verifique sua conexão";
        } else {
            console.error(error);
            error.message = "Algo deu errado";
        }
        return Promise.reject(error);
    });
}

export default ConfigurarAxios;