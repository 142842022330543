import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, CardTitle, FormGroup, Label } from 'reactstrap';
import { v4 } from 'uuid';
import FormUI from '../../../components/Templates/Form';
import { Card, CardActions, CardBody, CardHeader } from '../../../components/ui/Card';
import Api from '../../../lib/Api';
import Loading from '../../../lib/Loading';
export const AreaFormulario = props => {

    const { uuid } = useParams();
    const history = useHistory();
    const [inicialValues, setInicialValues] = useState({
        codigo: '',
        descricao: '',
        uuid: v4()
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async _ => {

            try {
                setIsLoading(true);
                if (uuid) {
                    const data = await Api.fetchArea(uuid);
                    setInicialValues({
                        codigo: data.codigo,
                        descricao: data.descricao,
                        uuid: data.uuid
                    });
                }
            } catch (error) {
                toast.error(error.messag);
            }
            finally {
                setIsLoading(false);
            }

        })();
    }, [])

    const validate = (values) => {
        const erros = {};
        if (!values.codigo) {
            erros.codigo = "É necessário informar o Código";
        }
        if (!values.descricao) {
            erros.descricao = "É necessário informar o Descrição";
        }
        return erros;
    }

    if (isLoading) return <Loading />

    return (
        <>
            <Card>
                <CardHeader>
                    <h2>{`Área`}</h2>
                </CardHeader>
                <CardBody>
                    <Formik initialValues={inicialValues} validate={validate} onSubmit={async (values, actions) => {
                        try {
                            if (uuid) {
                                await Api.atualizarArea(uuid, values);
                            } else {
                                await Api.adicionarArea(values);
                            }
                            toast.success("Salvo com sucesso!");
                            history.push('/areas');
                        } catch (error) {
                            toast.error(error.message)
                        } finally {
                            actions.setSubmitting(false);
                        }
                    }}>
                        {({ isSubmitting }) => (
                            <Form>
                                <FormGroup>
                                    <Label>Código</Label>
                                    <Field autoFocus className="form-control" name="codigo" />
                                    <ErrorMessage name='codigo' component='div' className='text-danger mt-2'/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Descricação</Label>
                                    <Field className="form-control" name="descricao" />
                                    <ErrorMessage name='descricao' component='div' className='text-danger mt-2'/>
                                </FormGroup>

                                <FormGroup>
                                    <Button type="submit" disabled={isSubmitting}>Salvar</Button>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
            {/* <FormUI
                entidade='Area'
                fields={fields}
                inicialValues={inicialValues}
                resource={'areas'}
                validate={validate}
            /> */}
        </>
    )
};