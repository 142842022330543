import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GetPooling, GetShouldPool } from '../store/reducers/parametros';
const usePooling = (startPolling, stopPolling) => {
    const pooling = useSelector(GetPooling);
    const shouldPool = useSelector(GetShouldPool);

    useEffect(() => {
        if (shouldPool)
            startPolling(pooling);
        return shouldPool ? () => stopPolling() : () => { }
    }, [])
};
export default usePooling