import Axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, CardBody, CardColumns, CardHeader, Row } from 'reactstrap';
import { Bar, Doughnut, Line, Pie, Polar, Radar } from 'react-chartjs-2';
import Loading from '../../../lib/Loading';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
const options = {
    tooltips: {
        enabled: false,
        custom: CustomTooltips
    },
    maintainAspectRatio: false
}
const Graficos = ({ esqueletoUuid }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const processData = async () => {
            const req = await Axios.get('/estatisticas/' + esqueletoUuid);
            setData(req.data);
            setIsLoading(false);

        };
        processData();

    }, [])

    const buffer = [];
    for (const key in data) {
        if (key === 'heatmap') {

        }
        if (data[key].type === "radio-group" || data[key].type === "number") {
            const bar = {
                labels: [],
                datasets: [
                    {
                        label: '.',
                        backgroundColor: 'rgba(255,99,132,0.2)',
                        borderColor: 'rgba(255,99,132,1)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                        hoverBorderColor: 'rgba(255,99,132,1)',
                        data: [],
                    },
                ],
            };

            for (const opt in data[key].data) {
                bar.labels.push(opt);
                bar.datasets[0].data.push(data[key].data[opt]);
            }

            buffer.push((
                <div className="col-xs-12 col-md-6 col-xl-4">
                    <Card>
                        <CardHeader>
                            {data[key].desc}
                        </CardHeader>
                        <CardBody>
                            <div className="chart-wrapper">
                                <Bar data={bar} options={options} />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            ))
        }

        if (data[key].type === "switch") {

            const pie = {
                labels: [
                ],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [

                        ],
                        hoverBackgroundColor: [
                        ],
                    }],
            };

            for (const opt in data[key].data) {
                const color = opt === "C" ? "#1b8eb7" : "#b04a54";

                pie.labels.push(opt === "C" ? "Conforme" : "Não Conforme");
                pie.datasets[0].data.push(data[key].data[opt]);
                pie.datasets[0].backgroundColor.push(color);
                pie.datasets[0].hoverBackgroundColor.push(color);
            }

            buffer.push((
                <div className="col-xs-12 col-md-6 col-xl-4">
                    <Card>
                        <CardHeader>
                            {data[key].desc}
                        </CardHeader>
                        <CardBody>
                            <div className="chart-wrapper">
                                <Pie data={pie} />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            ))


        }
    }

    if (isLoading) return <Loading />;


    return (
        <Row>
            {buffer}
        </Row>
    )
};
export default Graficos