import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import DatePickerPackage, { setDefaultLocale, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/pt-BR';
import moment from 'moment';
const DatePicker = ({ onSelect = () => { }, inicialValues = { ini: null, fim: null }, isOpen }) => {
    registerLocale('es', es)
    const [startDate, setStartDate] = useState(inicialValues.ini);
    const [endDate, setEndDate] = useState(inicialValues.fim);

    const btnChangeDataHandler = (dias) => {
        const x = moment().subtract(dias, 'days').startOf('day').toDate();
        setStartDate(x);
        setEndDate(moment().endOf('day').toDate());
    }

    const handler = (values) => {
        setStartDate(values.ini);
        setEndDate(values.fim);
        onSelect(values);
    }
    return (
        isOpen &&
        <div style={{ position: 'absolute', left: '100%', minWidth: "450px", transform: 'translateX(-100%) translateY(4px)', }}>
            <Card>
                <CardHeader className="d-flex align-items-center">
                    <h6 style={{ marginBottom: '0px' }}>Filtro Por Período</h6>
                </CardHeader>
                <CardBody>
                    <Row className="mb-3 align-items-center">
                        <Col sm="3">
                            <span>Início</span>
                        </Col>
                        <Col sm="9">
                            <DatePickerPackage
                                className='form-control'
                                timeInputLabel='Hora'
                                locale="es"
                                showTimeInput
                                dateFormat="Pp"
                                selected={startDate} onChange={(date) => setStartDate(date)} />
                        </Col>
                    </Row>
                    <Row className="mb-3 align-items-center">
                        <Col sm="3">
                            <span>Fim</span>
                        </Col>
                        <Col sm="9">
                            <DatePickerPackage
                                className='form-control'
                                timeInputLabel='Hora'
                                locale="es"
                                showTimeInput
                                dateFormat="Pp"
                                selected={endDate} onChange={(date) => setEndDate(date)} />
                        </Col>
                    </Row>
                    <div className='align-items-strech justify-content-between d-flex' style={{ gap: '1rem', flexWrap: 'nowrap' }}>
                        <Button size="sm" onClick={() => btnChangeDataHandler(0)} className="flex-grow-1">Hoje</Button>
                        <Button size="sm" onClick={() => btnChangeDataHandler(7)} className="flex-grow-1">Últimos 7 dias</Button>
                        <Button size="sm" onClick={() => btnChangeDataHandler(15)} className="flex-grow-1">Últimos 15 dias</Button>
                        <Button size="sm" onClick={() => btnChangeDataHandler(30)} className="flex-grow-1">Últimos 30 dias</Button>
                    </div>
                </CardBody>
                <CardFooter className="d-flex justify-content-end" style={{ gap: '1rem' }}>
                    <Button onClick={handler.bind(this, { ini: null, fim: null })} color="secondary">
                        Limpar Filtro
                    </Button>
                    <Button onClick={handler.bind(this, { ini: startDate, fim: endDate })} color="primary">
                        Filtrar
                    </Button>
                </CardFooter>
            </Card>
        </div>
    )
};
export default DatePicker