import React from 'react';
import { Link } from 'react-router-dom';
import TableTeamplate from '../../../components/Templates/Table/TableTeamplate';

export const Areas = props => {
    const headers = [
        { label: 'Código', filtravel: true, name: 'codigo' },
        { label: 'Descrição', filtravel: true, name: 'descricao' },
        { label: 'Ações' },
    ]
    const body = ['codigo', 'descricao',
        (cadastro) => <Link to={`/areas/editar/${cadastro.uuid}`}>
            <i className="cui-note icons font-2xl "></i>
        </Link>]

    return (
        <TableTeamplate
            end_point='/areas'
            titulo='Áreas'
            entidade='area'
            headers={headers}
            body={body}
            rota='areas'
            showNovo={false} />
    )
};