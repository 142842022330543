import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { API_URL } from '../lib/Api';
import { store } from '../store';
const httpLink = createHttpLink({
    uri: API_URL + '/graphql'
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = store.getState().auth.jwt;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            jwt: token ? token : "",
        }
    }
});

export const GraphqlCliente = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});