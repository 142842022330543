import React from "react";
import { AppSwitch } from "@coreui/react";
import { FormGroup, Label, Col } from "reactstrap";

const FieldRequired = ({ onChange, field }) => {
  const { id } = field;
  
  return (
    <React.Fragment>
      <FormGroup row>
        <Label xs={6} sm={2}>
          Obrigatório
        </Label>
        <Col xs={6} sm={10}>
          <AppSwitch
            id={id}
            checked={field.required}
            label={true}
            onChange={(t) => onChange({ required: t.target.checked })}
          />
        </Col>
      </FormGroup>
    </React.Fragment>
  );
};

export default FieldRequired;
