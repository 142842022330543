import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { Button, FormGroup, Label } from 'reactstrap';
import { v4 } from "uuid";
import Loading from '../../../lib/Loading';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Formik, ErrorMessage } from 'formik';
import { Card, CardBody, CardHeader } from '../../../components/ui/Card';

export const SetorForm = props => {

    const [areas, setAreas] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const { uuid } = useParams();

    const [setor, setSetor] = useState({
        descricao: '',
        uuid: v4(),
        area_id: ''
    });

    const history = useHistory();


    useEffect(() => {

        (async _ => {
            try {
                setIsLoading(true);
                var res = await Axios.get('/areas');
                setAreas(_ => res.data);
                if (uuid) {
                    const requestSetor = await Axios.get('/setores/' + uuid);
                    setSetor(requestSetor.data);
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        })();

    }, [])

    if (isLoading)
        return <Loading />

    return (
        <Card>
            <CardHeader >
                <h2>{'Setor'}</h2>
            </CardHeader>
            <CardBody>
                <Formik
                    validate={(values) => {
                        const errors = {};
                        if (!values.area_id) {
                            errors.area_id = "É necessário informar a Área";
                        }
                        if (!values.descricao) {
                            errors.descricao = "É necessário informar a descrição";
                        }
                        return errors;
                    }}
                    enableReinitialize
                    initialValues={setor}
                    onSubmit={async (values, actions) => {
                        try {
                            if (uuid) {
                                const req = await Axios.patch('/setores/' + uuid, values);
                                toast.success(req.data.mensagem);
                            } else {
                                const req = await Axios.post('/setores', values);
                                toast.success(req.data.mensagem);
                            }
                        } catch (error) {
                            toast.error(error.message);
                        }

                        actions.setSubmitting(false);
                        history.push('/setores');
                    }}>
                    {({ isSubmitting }) => (
                        <Form>
                            <FormGroup>
                                <Label>Área</Label>
                                <Field autoFocus name="area_id" as="select" className="form-control">
                                    <option value=""></option>
                                    {areas.map(area => (
                                        <option value={area.id}>{area.descricao}</option>
                                    ))}
                                </Field>
                                <ErrorMessage component="div" name='area_id' className='mt-2 text-danger' />
                            </FormGroup>
                            <FormGroup>
                                <Label>Descrição</Label>
                                <Field name="descricao" className="form-control"  />
                                <ErrorMessage component="div" name='descricao' className='mt-2 text-danger' />
                            </FormGroup>
                            <FormGroup>
                                <Button type="submit" disabled={isSubmitting}>Salvar</Button>
                            </FormGroup>
                        </Form>

                    )}
                </Formik>
            </CardBody>
        </Card>

    )


};