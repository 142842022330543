import axios from "axios"
import moment from "moment";

export default {

    fetchPreenchidos: async (esqueleto_uuid, filtroData, filtros) => {

        try {
            var url = '/v2/preenchidos?';
            if (esqueleto_uuid) {
                url += "esqueleto_uuid=" + esqueleto_uuid + "&";
            }
            if (filtroData) {
                if (filtroData.ini)
                    url += "data_ini=" + moment(filtroData.ini).format("YYYY-MM-DD HH:mm:ss") + "&";
                if (filtroData.fim)
                    url += "data_fim=" + moment(filtroData.fim).format("YYYY-MM-DD HH:mm:ss") + "&";
            }

            for (const key in filtros) {
                if (Object.hasOwnProperty.call(filtros, key)) {
                    if (filtros[key])
                        url += `${key}=${filtros[key]}&`
                }
            }

            const req = await axios.get(url)
            return [req.data, null];
        } catch (error) {
            return [null, error];
        }


    }

}
